
/* logo configration */
.logo-area img {
    height: auto;
    margin-left:16px !important;
}

.footer-area{
  width:100% !important
}

.footer-logo img {
    height: 64px;
}

/* end logo configration */
/* ==================================================================================================== */
/* Start Accessories */

/* End Accessories */
/* ==================================================================================================== */
.pressrelease-slider{
    margin:5px
}

.vision-mission .card {
    min-height: 300px;
    margin-bottom: 18px;
    background-color: #e1e6e8;
}

.vision-mission h5 {
    text-align: center;
}

.vision-mission .content {
    margin: 30px;
}

.vision-mission-values .card {
    min-height: 140px;
    background-color: #e1e6e8;
}

.vision-mission-values h5 {
    text-align: center;
    font-size: 30px;
}

.vision-mission-values .content {
    margin: 30px;
}

.vision-mission li {
    font-size: 16px !important;
}


/* side stickly */


#MiniLeftNav {
    position: fixed;
    top: 40%;
    right: 0;
    list-style: none;
    padding-left: 0;
    z-index: 10;
    margin: 0;
    -webkit-transition: right 0.25s ease-in-out;
    transition: right 0.25s ease-in-out;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

#MiniLeftNav li {
    list-style: outside none none;
    padding: 0;
    width: 40px;
}

#MiniLeftNav li a {
    border: solid 1px #fff;
    display: block;
    padding: 7px;
    position: relative;
    background: #3f4653;
}

#MiniLeftNav li a:hover {
    width: 40px;
    padding-left: 0;
    text-align: center;
    text-decoration: none;
    background: #E73853;
}

#MiniLeftNav span {
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
    bottom: 0;
    right: 47px;
    line-height: 38px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 0;

    /* CSS3 Transition: */
    -webkit-transition: 0.50s;
    /* Future proofing (these do not work yet): */
    -moz-transition: 0.50s;
    transition: 0.50s;
}

#MiniLeftNav a:hover span {
    width: auto;
    padding: 0 30px;
    overflow: visible;
    text-align: center;
    text-decoration: none;
    background: #E73853;
    color: #ffffff;
}

#MiniLeftNav a span {
    background-color: #fff;
    color: #3d4f0c;
}



.fa-facebook,
.fa-facebook-square {
    color: #1976F2;
}

.fa-twitter,
.fa-twitter-square {
    color: #00aced;
}

.fa-google-plus,
.fa-google-plus-square {
    color: #dd4b39;
}

.fa-youtube,
.fa-youtube-play,
.fa-youtube-square {
    color: #bb0000;
}

.fa-linkedin,
.fa-linkedin-square {
    color: #035479;
}

.fa-linkedin-in{
    color: #035479;
}

.topbar-inner{
    margin-top: 2px;
    margin-bottom: 2px;
}
.fa-instagram {
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 98%);
    -webkit-background-clip: text;
    /* Also define standard property for compatibility */
    background-clip: text;
    -webkit-text-fill-color: transparent;
}


.carousel-inner {
    box-shadow: rgba(50, 44, 93, 0.25) 0px 6px 18px -5px, rgba(0, 0, 0, 0.3) 0px 8px -1px 0px;
}

header {
    box-shadow: rgba(50, 44, 93, 0.25) 0px 6px 0px -5px, rgba(0, 0, 0, 0.3) 0px 8px 10px 0px;
}

.follow li {
    font-size: 12px !important;
}

.parts .card {
    min-height: 300px;
}


@media (min-width: 990px) and (max-width: 1200px) {
    .navbar-area .nav-container .navbar-collapse .navbar-nav li a {
        padding: 0 8px !important;
    }
}

@media only screen and (max-width: 992px) {
    .quick-find-us .find-us-inner {
        max-width: none;
        margin-bottom: 0;

    }

}
.navbar-nav li a{
    font-family: 'Montserrat', sans-serif !important
}

.layout {
    min-height: 150vh;
    display: flex;
    flex-direction: column;
}


/* Bottom left text */
.bottom-left {
    position: absolute;
    bottom: 8px;
    left: 16px;
}






		/* Certificate Section */

.certificate-section {
  position: relative;
  padding: 120px 0 90px;
}

.certificate-section.no-pd-btm {
  padding-bottom: 0;
}

.certificate-section .sec-title {
  margin-bottom: 30px;
}

.certificate-section .carousel-outer {
  position: relative;
  margin: 0 -30px;
}

.certificate-carousel {
  position: relative;
}

.certificate-carousel .slide-item {
  position: relative;
  padding: 30px;
}

.certificate-carousel .image-box {
  position: relative;
  max-width: 350px;
  margin: 0 auto;
}

.certificate-carousel .image-box .image {
  position: relative;
  margin-bottom: 0;
  border: 10px solid #ffffff;
  -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}

.certificate-carousel .image-box img {
  position: relative;
  display: block;
  width: 100%;
}

.certificate-carousel .owl-nav {
  display: none;
}


@media only screen and (max-width: 1200px) {
    .max1200 {
      max-width: auto;
    }
  }
  
  .min1200 {
    min-width: 1200px;
  }
  
  @media only screen and (max-width: 1200px) {
    .min1200 {
      min-width: 1000px;
    }
  }
  
  @media only screen and (max-width: 1000px) {
    .min1200 {
      min-width: 800px;
    }
  }
  
  @media only screen and (max-width: 800px) {
    .min1200 {
      min-width: auto;
    }
  }
  
  .each_af_features {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    padding: 20px 5px 20px 10px;
    border: 1px solid #d9d9d9;
    min-height: 100px;
  }
  
  .each_af_features .icon_container {
    width: 60px;
    min-width: 60px;
    text-align: center;
    margin: auto 0px;
  }
  
  .each_af_features .icon_container i {
    font-size: 2rem;
    color: #27a3e2;
  }
  
  .each_af_features .text_container {
    margin: auto 0px;
  }
  
  .each_af_features .text_container h3 {
    font-size: 0.8rem;
    color: #3f4d50;
    margin-bottom: 0px;
  }
  
  .each_af_features .text_container p {
    font-size: 0.8rem;
    margin-bottom: 0px;
  }
  
  .packages_caroseul_dsg .each_package_container {
    margin: 20px 10px;
  }
  .packages_caroseul_dsg_another .each_package_container {
    margin: 20px 10px;
  }
  
  .each_package_container {
    position: relative;
    background-color: #f2f2f2;
    border-radius: 10px;
    margin: 60px 0px 80px 0px;
    -webkit-transition: -webkit-box-shadow 0.3s ease-in;
    transition: -webkit-box-shadow 0.3s ease-in;
    transition: box-shadow 0.3s ease-in;
    transition: box-shadow 0.3s ease-in, -webkit-box-shadow 0.3s ease-in;
  }
  
  .each_package_container:hover {
    -webkit-box-shadow: 0 0px 10px rgba(0, 0, 0, 0.4);
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.4);
  }
  
  .each_package_container.active {
    -webkit-box-shadow: 0 0px 15px rgba(0, 0, 0, 0.3);
    box-shadow: 0 0px 15px rgba(0, 0, 0, 0.3);
  }
  
  .each_package_container .content_package {
    position: relative;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    color: black;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 15px;
    min-height: 400px;
  }
  
  .each_package_container .content_package .btn_container {
    margin-top: auto;
    margin-bottom: 0px;
  }
  
  .each_package_container .content_package h3 {
    font-size: 1rem;
  }
  .content_package {
    background-color: #d3d3d3;
    border-radius: 10px;
  }
  
  @media only screen and (max-width: 767px) {
    .each_package_container {
      margin: 0px;
    }
  }
  
  .points_container ul {
    padding: 0px;
  }
  
  .points_container ul li {
    display: inline-block;
    width: 100%;
    position: relative;
    padding: 0px 0px 0px 25px;
    font-size: 0.8rem;
    color: black;
    font-weight: 400;
  }
  
  .points_container ul li:before,
  .points_container ul li::after {
    position: absolute;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    left: 0;
    top: 0px;
    color: green;
    font-size: 1rem;
  }
  
  .points_container ul li.included:before {
    content: "\f00c";
  }
  
  .points_container ul li.excluded:before {
    content: "\f00d";
    color: #ce1432;
  }
  
  .each_service_package {
    background-color: #e2e2e2b8;
    padding: 20px 10px;
    text-align: center;
    min-height: 200px;
  }
  
  .each_service_package .icon_container {
    height: 100px;
    width: 100%;
  }
  
  .each_service_package .icon_container img {
    height: 100%;
    width: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    min-height: 80px;
    max-height: 80px;
  }
  
  .each_service_package .text_container {
    margin-top: 10px;
  }
  
  .each_service_package .text_container h3 {
    font-size: 1rem;
    font-weight: 600;
  }
  
  .each_brand_category {
    border: 2px solid #cbcbcb;;
    background-color: white;
    padding: 20px 10px;
    text-align: center;
    min-height: 100px;
  }
  
  .each_brand_category .icon_container {
    height: 80px;
    width: 100%;
  }
  
  .each_brand_category .icon_container img {
    height: 100%;
    width: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    min-height: 80px;
    max-height: 80px;
  }
  
  .each_brand_category .text_container {
    margin-top: 10px;
    display: none;
  }
  
  .each_brand_category .text_container h3 {
    font-size: 1rem;
    font-weight: 600;
    color: #555555;
  }
  
  .each_brand_category:hover {
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
  }

  .et-icon-box-container[data-gap="8"] {
    grid-row-gap: 8px;
    grid-column-gap: 8px;
  }
  
  .et-item-set[data-gap="8"] .et-item {
    padding-left: 4px;
    padding-right: 4px;
    padding-bottom: 8px;
  }
  
  .et-item-set[data-gap="8"] .et-item {
    padding-left: 4px;
    padding-right: 4px;
    padding-bottom: 8px;
  }
  
  .et-icon-box-container {
    position: relative;
    display: -ms-grid;
    display: grid;
    grid-row-gap: 1px;
    grid-column-gap: 1px;
    justify-items: stretch;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding: 1px 0.5px 1px 1px;
  }
  
  .et-icon-box {
    position: relative;
    padding: 48px 32px;
  }
  
  .et-icon-box {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: color 150ms ease-out, background-color 150ms ease-out,
      -webkit-box-shadow 150ms ease-out;
    transition: color 150ms ease-out, background-color 150ms ease-out,
      -webkit-box-shadow 150ms ease-out;
    transition: color 150ms ease-out, background-color 150ms ease-out,
      box-shadow 150ms ease-out;
    transition: color 150ms ease-out, background-color 150ms ease-out,
      box-shadow 150ms ease-out, -webkit-box-shadow 150ms ease-out;
  }
  
  .et-make {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    padding: 12px 8px 12px 8px;
    justify-self: center;
  }
  
  @media only screen and (min-width: 575px) {
    .et-make-container.column-8 {
      -ms-grid-columns: (6fr) [6] !important;
      grid-template-columns: repeat(6, 6fr) !important;
    }
  }
  
  @media only screen and (max-width: 768px) {
    .et-make-container.column-8 {
      -ms-grid-columns: (6fr) [6] !important;
      grid-template-columns: repeat(6, 6fr) !important;
    }
  }
  
  @media only screen and (max-width: 675px) {
    .et-make-container.column-8 {
      -ms-grid-columns: (5fr) [5] !important;
      grid-template-columns: repeat(5, 5fr) !important;
    }
  }
  
  @media only screen and (max-width: 575px) {
    .et-make-container.column-8 {
      -ms-grid-columns: (4fr) [4] !important;
      grid-template-columns: repeat(4, 4fr) !important;
    }
  }
  
  @media only screen and (max-width: 475px) {
    .et-make-container.column-8 {
      -ms-grid-columns: (3fr) [3] !important;
      grid-template-columns: repeat(3, 3fr) !important;
    }
  }
  
  @media only screen and (max-width: 375px) {
    .et-make-container.column-8 {
      -ms-grid-columns: (2fr) [2] !important;
      grid-template-columns: repeat(2, 2fr) !important;
    }
  }
  
  @media only screen and (min-width: 1280px) {
    .et-make-container.column-8 {
      -ms-grid-columns: (5fr) [5] !mportant;
      grid-template-columns: repeat(5, 5fr) !important;
    }
  }

  .service_title{
    font-size: 16px;
    font-weight: 700;
  }
  .service_div .card{
    min-height: 180px !important;
    background-color: #f0f0f0;
  }

  .service_div p{
    font-size:13px !important;
    width:85%;
    margin: auto;
  }


  .sticky-nav-container {
    position: fixed;
    width: 100%;
  
  background-color: transparent;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

}

.sticky-nav-container {
  background-color: #ffffff;
  margin-top: 0px !important;
}

.scrolled{
  margin-top: -42px;
}
.about-story-section #carouselExampleControls{
  margin-top: -9px;
}



 